$( document ).ready(function() {

  $(window).scroll(function() {
  if ($(this).scrollTop() > 1){  
      $('.top-header').addClass("sticky");
      $('.Content').css("padding-top", $(".top-header").outerHeight( true ) + "px");
      // $('.Content').css("padding-top", "86px");
    }
    else{
      $('.top-header').removeClass("sticky");
      $(".Content").css("padding-top", "0");
    }
  });

   $('.animated').css('opacity', 0);

   $('.text-parallax').waypoint(function() {
      $('.text-parallax').addClass('fadeInUp');
  }, { offset: '50%' });

   $('.services-index').waypoint(function() {
      $('.services-index').addClass('fadeInUp');
  }, { offset: '70%' });

   $('.blue-bg-index').waypoint(function() {
      $('.blue-bg-index').addClass('fadeInLeft');
  }, { offset: '70%' });

   $('.about-text-container').waypoint(function() {
      $('.about-text-container').addClass('fadeInRight');
  }, { offset: '70%' });

   $('.catalogue-products-container').waypoint(function() {
      $('.catalogue-products-container').addClass('fadeInUp');
  }, { offset: '70%' });

   $('.form').waypoint(function() {
      $('.form').addClass('fadeInUp');
  }, { offset: '70%' });

   $('.gallery-row').waypoint(function() {
      $('.gallery-row').addClass('fadeInUp');
  }, { offset: '70%' });

   $('.detail-product-description').waypoint(function() {
      $('.detail-product-description').addClass('fadeInRight');
  }, { offset: '70%' });



	$(".wrap_desktop").camera({
      loader: "none",
      navigation: true,
      fx: 'scrollHorz',
      pagination: false,
      height:'33%',
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      transPeriod: 700,
      pauseOnClick: false,
      playPause: false,
      time: 2000,
      alignment: 'center',
      // pauseOnClick: false
   });  

   $(".wrap_mobile").camera({
      loader: "none",
      navigation: true,
      fx: 'scrollHorz',
      pagination: false,
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      height:'400',
      transPeriod: 700,
      pauseOnClick: false,
      playPause: false,
      time: 2000,
      alignment: 'center',
      // pauseOnClick: false
   }); 

  //  $(".parallax-window").parallax({
  //   imageSrc: "assets/img/parallax_home.jpg",
  //   speed: 0.4,
  // });

  //  $(".parallax-window-about").parallax({
  //   imageSrc: "assets/img/parallax_about.jpg",
  //   speed: 0.4,
  // });

  //  $(".parallax-window-catalogue").parallax({
  //   imageSrc: "assets/img/parallax_catalogue.jpg",
  //   speed: 0.4,
  // });


  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
    //$("h1 span.version").text($.fn.sidebar.version);
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".btn[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        $(".sidebar." + side).css("display", "block");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });
}
});