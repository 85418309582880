$( document ).ready(function() {
	lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true,
      'albumLabel': "Imagen %1 of %2",
      'disableScrolling' : true,
    })

   $.each($(".img-container"), function() {
      $(this).css('background-image', "url(" + $(this).data("src") + ")");
   });

   $(".product").matchHeight();

});